/* ANGULAR MODULES */
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
/* END ANGULAR MODULES */
/* VENDOR MODULES*/
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { Restangular, RestangularModule } from 'ngx-restangular'
import { switchMap } from 'rxjs/operators'
/* END VENDOR MODULES*/
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ComponentsModule } from './components/components.module'
import { CallbackComponent } from './legacy/components/auth/callback/callback.component'
import { HttpsRequestInterceptor } from './legacy/components/auth/interceptor.module'
import { CoreModule } from './legacy/core/core.module'
import { ClickOutsideDirective } from './legacy/core/helpers/click-outside.directive'
import { BaseService } from './legacy/core/services/basic.service'
import { UserService } from './legacy/core/services/user.service'
import { TranslationModule } from './legacy/core/translation/translation.module'
import { DeveloperPortalComponent } from './legacy/developer-portal/developer-portal.component'
import { AdminLayoutComponent } from './legacy/layouts/admin-layout/admin-layout.component'
import { MainHeaderComponent } from './legacy/layouts/main-header/main-header.component'
import { MenuDropdownAppsComponent } from './legacy/layouts/main-header/menu-dropdown-apps/menu-dropdown-apps.component'
import { MenuDropdownFaqComponent } from './legacy/layouts/main-header/menu-dropdown-faq/menu-dropdown-faq.component'
import { MenuDropdownLanguageComponent } from './legacy/layouts/main-header/menu-dropdown-language/menu-dropdown-language.component'
import { MenuDropdownUserComponent } from './legacy/layouts/main-header/menu-dropdown-user/menu-dropdown-user.component'
import { MenuHeaderComponent } from './legacy/layouts/menu-header/menu-header.component'
import { UserOnboardingComponent } from './legacy/layouts/user-onboarding/user-onboarding.component'
import { PagesModule } from './pages/pages.module'
//////
export let AppInjector: Injector

export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.setBaseUrl('/Api')
  RestangularProvider.setPlainByDefault(true)
  RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
    if (response.status === 401) {
      if (AppInjector.get(UserService).refreshingToken) {
        AppInjector.get(UserService).requestsPendingRefreshToken.push({ response, subject, responseHandler })
      } else {
        AppInjector.get(UserService)
          .refreshAccesstoken()
          .pipe(
            switchMap((refreshAccesstokenResponse) => {
              const newHeaders = new HttpHeaders({ Authorization: `Bearer ${localStorage.getItem('token')}` })
              const newReq = response.request.clone({ headers: newHeaders })
              return response.repeatRequest(newReq)
            })
          )
          .subscribe(
            (res) => responseHandler(res),
            (err) => subject.error(err)
          )
      }
      return false
    } else {
      return true
    }
  })
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    return {
      headers: Object.assign({}, headers, {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      })
    }
  })

  RestangularProvider.addResponseInterceptor((data) => {
    return data
  })
}

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    RestangularModule.forRoot(RestangularConfigFactory),
    TranslationModule,
    PagesModule,
    ComponentsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    CallbackComponent,
    ClickOutsideDirective,
    MainHeaderComponent,
    MenuHeaderComponent,
    MenuDropdownUserComponent,
    MenuDropdownLanguageComponent,
    MenuDropdownFaqComponent,
    MenuDropdownAppsComponent,
    UserOnboardingComponent,
    DeveloperPortalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true // Allows multiple interceptors
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private translateService: TranslateService,
    private restangular: Restangular,
    private injector: Injector
  ) {
    this.configInternazionalization()
    this.getStatusApi()
    AppInjector = this.injector
  }

  configInternazionalization() {
    const defautlLanguage = 'pt-br'
    const langs = [defautlLanguage, 'en', 'es']
    let userLanguage = localStorage.getItem('user-language')
    if (!userLanguage || userLanguage === null || userLanguage === '') {
      userLanguage = this.translateService.getBrowserLang()
    }
    if (userLanguage.indexOf('pt') >= 0) {
      userLanguage = defautlLanguage
    }
    if (langs.indexOf(userLanguage) < 0) {
      userLanguage = defautlLanguage
    }
    this.translateService.addLangs(langs)
    this.translateService.setDefaultLang(userLanguage)
    this.translateService.use(langs.indexOf(userLanguage) >= 0 ? userLanguage : defautlLanguage)
  }

  async getStatusApi() {
    const service = new BaseService(this.restangular)
    service.setEntity('status')
    service.setApiType('ContractRegistration')
    const { results = [] } = await service.findAll({ take: 1000000 })
    const id = (results.find((status) => status.name.toUpperCase() === 'ATIVO') || results[0]).id

    window.localStorage.setItem('statusId', id)
  }
}
