import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LoginService } from 'app/legacy/auth/services/userLogin.service'
import { throwError } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators'

const whiteListPermissionManagement = ['/user/password/forgot', '/user/login', 'user/signin', 'user/refreshtoken']

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(
    private rt: LoginService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/Api/PermissionManagement') && !whiteListPermissionManagement.includes(req.url)) {
      const token = window.localStorage.getItem('token')
      const refreshToken = window.localStorage.getItem('refreshToken')
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
      return next.handle(clonedReq).pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.verifyRefreshToken(refreshToken)
          }
          return throwError(error)
        })
      )
    }

    return next.handle(req)
  }

  verifyRefreshToken(refreshToken: string) {
    if (refreshToken) {
      this.rt.refreshToken(refreshToken).subscribe((res) => {
        const newToken = res.token
        const newRefreshToken = res.refreshToken
        window.localStorage.setItem('token', newToken)
        window.localStorage.setItem('refreshToken', newRefreshToken)
      })
    } else {
      this.router.navigate(['/login'])
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
    }
  }
}
