import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BaseService } from 'app/legacy/core/services/basic.service'
import { cloneDeep } from 'lodash'
import { Restangular } from 'ngx-restangular'
import { MessagesService } from './../../services/messages.service'
import { TranslationPipe } from './../../translation/translation.pipe'
import { StepParamsService } from './service/step-params.service'

@Component({
  selector: 'app-step-params-dialog',
  templateUrl: './step-params-dialog.component.html',
  styleUrls: ['./step-params-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepParamsDialogComponent implements OnInit {
  public paramTypes = [{ id: 'TIMEOUT', text: 'trl_timeout' }]
  public features: any[]
  public comboDocumentos: any[]
  public dataView: any
  public isLoadParams = false

  constructor(
    private service: StepParamsService,
    public dialogRef: MatDialogRef<StepParamsDialogComponent>,
    private messageService: MessagesService,
    private translationPipe: TranslationPipe,
    private restangular: Restangular,
    @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {}

  ngOnInit(): void {
    this.dataView = cloneDeep(this.data)
    this.configureSteps()
  }

  async configureSteps() {
    const service = new BaseService(this.restangular)
    service.setEntity('Step')
    const step = await service.getOne(this.dataView.stepId)
    this.dataView.identifier = step.identifier
    this.dataView.parameters = this.dataView.parameters || []
    if (this.dataView.name.toUpperCase().includes('BIGDATA')) {
      this.paramTypes.push({ id: 'FEATURES', text: 'FEATURES' })
      this.findFeatures()
      this.configureParameters()
    } else {
      this.isLoadParams = true
    }
    if (this.dataView.identifier === 'SWORKS' || this.dataView.identifier === 'MODEL_REPOSITORY' || this.dataView.identifier === 'DEEP_FAKE_KEY_WORD_REPLICA') {
      this.paramTypes.push({ id: 'WORKFLOW', text: 'WORKFLOW' })
    }

    if (this.dataView.identifier === 'SAMPLING') {
      this.paramTypes.push({ id: 'CONFIDENCE', text: 'trl_calculator_confidence' })
      this.paramTypes.push({ id: 'ERROR_MARGIN', text: 'trl_calculator_error_margin' })
    }

    if (this.dataView.identifier === 'AUTOMATIC_PENDING') {
      this.paramTypes.push({ id: 'DOCUMENTOS', text: 'DOCUMENTOS' })
      this.paramTypes.push({ id: 'ASSERTIVIDADE', text: 'ASSERTIVIDADE' })
      this.loadDocumentos()
      this.configureParameters()
    } else {
      this.isLoadParams = true
    }
  }

  checkDisableSave() {
    return this.dataView.order && !this.dataView.parameters.find((p) => !p.value)
  }

  async save() {
    if (this.dataView.parameters.length > 1) {
      if (this.dataView.parameters[0].name === this.dataView.parameters[1].name) {
        this.messageService.showNotification(
          this.translationPipe.transform('trl_two_equal_parameters_are_not_allowed'),
          'error'
        )
        return
      }
    }
    const data = cloneDeep(this.dataView)
    if (data.parameters?.length) {
      data.parameters.forEach((param) => {
        let value: string
        if (param.name === 'FEATURES' || param.name === 'DOCUMENTOS') {
          param.value.forEach((valueParam) => {
            value = value ? value + ',' + valueParam : valueParam
          })
          param.value = value
        }
      })
    }
    this.dialogRef.close(data)
  }

  add() {
    this.dataView.parameters.push({})
  }

  deleteParam(index: number) {
    this.dataView.parameters.splice(index, 1)
  }

  async findFeatures() {
    const response = await this.service.findAllMapNormalization('BIGDATA')
    const features = []
    response.forEach((feature) => {
      features.push({ key: feature.key, text: `trl_${feature.key}` })
    })
    this.features = features
  }

  configureParameters() {
    const dataParams = cloneDeep(this.data.parameters)
    if (this.data.parameters?.length) {
      dataParams.forEach((param) => {
        if (param.name === 'FEATURES' || param.name === 'DOCUMENTOS') {
          const params = param.value.split(',')
          const values = []
          params.forEach((value) => {
            values.push(value)
          })
          param.value = values
        }
      })
      this.dataView.parameters = dataParams
      this.isLoadParams = true
    }
  }

  loadDocumentos() {
    this.comboDocumentos = [
      { id: 'CNH', text: 'CNH' },
      { id: 'CRM', text: 'CRM' },
      { id: 'OAB', text: 'OAB' },
      { id: 'RG', text: 'RG Verso' },
      { id: 'RG_FRENTE', text: 'RG Frente' },
      { id: 'RNE', text: 'RNE' },
      { id: 'Selfie', text: 'Selfie' },
      { id: 'ABERTURA_CREDITO', text: 'Abertura Credito' },
      { id: 'CCB C6', text: 'CCB C6' },
      { id: 'CCB', text: 'CCB Eletronica' },
      { id: 'ADF', text: 'Ficha Cadastral ADF' },
      { id: 'TRILHA_AUDITORIA', text: 'Trilha de Auditoria' },
      { id: 'DOSSIE_COMPROBATORIO', text: 'Dossiê Comprobatório' },
      { id: 'COMPROVANTE_DENDERECO', text: 'Comprovante de endereço' },
      { id: 'LIMITE_CREDITO', text: 'Limite Credito Desconto Folha' },
      { id: 'PROCURACAO', text: 'Procuração' },
      { id: 'DOC_IDENTIFICACAO', text: 'Documento de identificação' },
      { id: 'CONTRATO', text: 'Contrato' },
      { id: 'COMPROVANTE_RESIDENCIA', text: 'Comprovante de residência' },
      { id: 'CERTIDAO_DE_OBITO', text: 'Certidão de Óbito' },
      { id: 'EXAME_MEDICO', text: 'Exame Médico' },
      { id: 'DECLARACAO_DE_HERDEIROS_PG1', text: 'Declaração de Herdeiros Página 1' },
      { id: 'DECLARACAO_DE_HERDEIROS_PG2', text: 'Declaração de Herdeiros Página 2' },
      { id: 'RELATORIO_MEDICO_MORTE', text: 'Relatório Médico de Morte' },
      {
        id: 'AUTORIZACAO_DE_PAGAMENTO_CREDITO_EM_CONTA_PESSOA_FISICA',
        text: 'Autorização de Pagamento de Crédito em Conta Pessoa Física'
      },
      { id: 'NOTA_FISCAL_DAS_DESPESAS_COM_FUNERAL', text: 'Nota Fiscal Funerária' },
      { id: 'APOLICE_HDI _SEGUROS', text: 'Apólice HDI Seguros' }
    ]
  }
}
